import { ACTION_AJAFFE_JEWELLERY_DATA, ACTION_B2B_ITEM_DATA } from "src/Services/Constant/types"

// **  Initial State
const initialState = {
    jewelleryData: { list: [], pagination: {}},
    B2B_Item_Data: { list: []}
}

const AjaffeJewelleryDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_AJAFFE_JEWELLERY_DATA:
            return {
                ...state,
                jewelleryData: action.data
            }
        case ACTION_B2B_ITEM_DATA:
            return {
                ...state,
                B2B_Item_Data: action.data
            }
        default:
            return state
    }
}

export default AjaffeJewelleryDataReducer