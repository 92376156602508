const Notes = ({ data, handleChange }) => {
    return <textarea
        id={data?.uId}
        value={data?.Note || ''}
        onChange={handleChange}
        name="Note"
        rows={4}
        placeholder="Please provide notes"
        className="mt-4 border w-full placeholder:text-gray-500 focus:ring-0 outline-none   p-1.5 px-2 rounded-md " />
}

export default Notes;