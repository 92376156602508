import { ACTION_GET_INVOICES, ACTION_EMAIL_INVOICES } from "src/Services/Constant/types"

// **  Initial State
const initialState = {
    invoices: { list: [], pagination: {} },
    emailInvoices: {list: []}
}

const InvoicesReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_GET_INVOICES:
            return {
                ...state,
                invoices : action.data
            }
        case ACTION_EMAIL_INVOICES:
            return {
                ...state,
                emailInvoices : action.data
            }
        default:
            return state
    }
}

export default InvoicesReducer