import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { GET_SHIP_ACCOUNTS } from 'src/Services/Redux/Actions/shippingDetails'

const ShipAccountDropdown1 = ({ props: { setValue, name, getValues } }) => {
    const dropdownRef = useRef(null)
    const [isOpen, setIsOpen] = useState(false)
    const [shipAccountOptions, setShipAccountOptions] = useState([
        // { account: 'A', otherProperty: 'Account A' },
        // { account: 'B', otherProperty: 'Account B' },
        // { account: 'V', otherProperty: 'Account V' }
    ])
    const [searchTerm, setSearchTerm] = useState('')
    const dispatch = useDispatch()

    useEffect(() => {
        const handleOutsideClick = event => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false)
            }
        }

        document.addEventListener('mousedown', handleOutsideClick)

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick)
        }
    }, [])

    const handleSearchChange = async (e) => {
        setSearchTerm(e.target.value)
        try {
            const salesGroup = window.localStorage.getItem('salesgroup') || '';
            const payload = { ShippingAccount: e.target.value, SalesGroup: salesGroup}
            const response = await dispatch(GET_SHIP_ACCOUNTS(payload))
            // if (response && Array.isArray(response)) {
            //     // Assuming the API returns an array of objects, each with 'account' and 'otherProperty'
            //     setShipAccountOptions(response)
            // } else {
            //     setShipAccountOptions([])
            // }
            if (response && response.status === 200 || response.status === 201) {
                const customerAccounts = response?.rows.map(row => row.CUSTOMERACCOUNT);
                const customerNames = response?.rows.map(row => row.ORGANIZATIONNAME);
                const combinedData = customerAccounts.map((account, index) => ({
                    account,
                    otherProperty: customerNames[index]
                }));

                setShipAccountOptions(combinedData);

            } else if (response && response.success === false) {
                console.error(response.message);
                setShipAccountOptions([]);
            } else {
                setShipAccountOptions([]);
            }

        } catch (error) {
            console.error("Error fetching ship accounts:", error);
            setShipAccountOptions([])
        }
    }

    const handleSelect = (account, otherProperty) => {
        setValue(name, account);
        console.log("Selected otherProperty:", otherProperty);
        setIsOpen(false);
        setSearchTerm(account);
    }

    return (
        <div ref={dropdownRef} className="relative w-full">
            <input
                type="text"
                value={searchTerm || getValues?.ShippingAccount || ""}
                onChange={handleSearchChange}
                onClick={() => setIsOpen(true)}
                placeholder="Search Ship Account"
                className="text-sm border w-full focus:ring-0 outline-none placeholder:text-gray-900 p-2 rounded-md"
            />

            {isOpen && (
                <div className="absolute z-50 p-2 min-w-[290px] transition ease-in duration-100 mt-1 w-full max-h-72 overflow-scroll rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {shipAccountOptions.length > 0 ? (
                        shipAccountOptions.map((item, index) => (
                            <div
                                key={index}
                                onClick={() => handleSelect(item.account, item.otherProperty)}
                                className="cursor-pointer hover:bg-gray-300 p-2 flex items-center justify-between border-b border-gray-100"
                            >
                                <div className="w-1/2 font-medium">{item.account}</div>
                                <div className="w-1/2 text-gray-600">{item.otherProperty}</div>
                            </div>
                        ))
                    ) : (searchTerm ? (<div className='p-2'>No matching account found</div>) : null)}
                </div>
            )}
        </div>
    );
}

export default ShipAccountDropdown1;