import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom';
import {GET_B2B_ITEM_DATA} from 'src/Services/Redux/Actions/ajaffeJewelleryData';

const ProductPage = () => {
    const dispatch = useDispatch()
    const location = useLocation();
    const ringData = location.state?.ringData;

    const ringData_Details = useSelector(state => state?.AjaffeJewelleryDataReducer?.B2B_Item_Data)

    const [selectedMetal, setSelectedMetal] = useState('');
    const [selectedClarity, setSelectedClarity] = useState('None');
    const [caratWeight, setCaratWeight] = useState('');
    const [stoneShape, setStoneShape] = useState('');
    const [ringSize, setRingSize] = useState('');
    const [retailPrice, setRetailPrice] = useState('');
    const [itemName, setItemName] = useState('');
    const [category, setCategory] = useState('');
    const [imageUrl, setImageUrl] = useState('');

    const [availableMetals, setAvailableMetals] = useState([]);
    const [availableClarity, setAvailableClarity] = useState(["GH/VS", "GH/SI", ""]);
    const [availableCaratWeights, setAvailableCaratWeights] = useState(["1.5", "1.0", "0.5", "0"]);
    const [availableShapes, setAvailableShapes] = useState(["", "Round", "Oval", "Assher"]);

    useEffect(() => {
        console.log("Ring DATA Details: ", ringData_Details.list);
        if (!Array.isArray(ringData_Details.list)) {
            throw new Error("API did not return an array for 'list'");
        }


        // Extract unique values, handling potentially missing values and empty strings
        const extractUnique = (arr, key) => {
            if (!Array.isArray(arr)) {
                return []; // Return an empty array if 'arr' is not an array
            }
            const values = arr.map(item => item[key]).filter(value => value !== undefined && value !== null && value !== "");
            return [...new Set(values)];
        }

        const metals = extractUnique(ringData_Details.list, "Metal");
        const clarities = extractUnique(ringData_Details.list, "Primary_Gemstone_Clarity");
        const caratWeights = extractUnique(ringData_Details.list, "Carat_Weight").map(String); // Convert to string for consistency
        const shapes = extractUnique(ringData_Details.list, "Primary_Gemstone_Shape");


        setAvailableMetals(metals);
        setAvailableClarity(clarities);
        setAvailableCaratWeights(caratWeights);
        setAvailableShapes(shapes);
    }, [ringData_Details])

    useEffect(() => {
        if (ringData) {
            setSelectedMetal(ringData.Metal || '');
            setSelectedClarity(ringData.Primary_Gemstone_Clarity || '');
            setCaratWeight(ringData.Primary_Gemstone_Weight || '');
            setStoneShape(ringData.Primary_Gemstone_Shape || '');
            setRingSize(ringData.Size || '');
            setRetailPrice(ringData.Unit_Price || '');
            setItemName(ringData.Style || '');
            setCategory(ringData.Category || '');
            setImageUrl(ringData.Primary_Image_URL || 'placeholder_image.jpg');
        } else {
            console.log("No ring data received");
        }

        if (ringData.ITEMID) {
            const formData = {
                "ITEMID": `${ringData.ITEMID}`
            };
            console.log("PAYLOAD: ", formData);
            dispatch(GET_B2B_ITEM_DATA(formData));
        } else {
            console.error("ringData.ITEMID is undefined");
        }
    }, [ringData]);

    useEffect(() => {
        console.log("IMage URL: ", imageUrl);
    }, [imageUrl]);

    // Function to update the image URL based on selected metal
    const updateImageUrl = (metal) => {
        if (ringData_Details.list && Array.isArray(ringData_Details.list)) {
            const filteredData = ringData_Details.list.find(item => item.Metal === metal);
            setImageUrl(filteredData ? filteredData.Primary_Image_URL : (ringData?.Primary_Image_URL || 'placeholder_image.jpg'));
        }
    };

    const handleMetalChange = (metal) => {
        setSelectedMetal(metal);
        updateImageUrl(metal);
    };

    const handleClarityChange = (clarity) => {
        setSelectedClarity(clarity);
    };

    const handleCaratChange = (weight) => {
        setCaratWeight(weight);
    };

    const handleShapeChange = (shape) => {
        setStoneShape(shape);
    };
    return (
        <>
            <div className="container max-w-full px-4 py-8 bg-white">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div>
                        {/* <img
                            src={ringData?.Primary_Image_URL || 'placeholder_image.jpg'}
                            alt={ringData?.itemnumber || 'Ring'}
                            className="w-full h-full object-cover rounded-md mb-2"
                        /> */}
                        <img
                            src={imageUrl}
                            alt={ringData?.itemnumber || 'Ring'}
                            className="w-full h-full object-cover rounded-md mb-2"
                        />
                    </div>

                    <div>
                        <h1 className="text-3xl font-bold">{itemName} - {selectedMetal} - {selectedClarity} - {stoneShape}</h1>

                        <div className="mt-4">
                            {availableMetals.map((metal) => (
                                <button
                                    key={metal}
                                    onClick={() => handleMetalChange(metal)}
                                    className={`mr-2 px-4 py-2 mb-4 rounded-full border ${selectedMetal === metal ? 'bg-gray-200 border-gray-400' : 'border-gray-300'}`}
                                >
                                    {metal}
                                </button>
                            ))}
                        </div>

                        <div className="mt-4">
                            <label htmlFor="clarity" className="block font-medium">Primary Gemstone Clarity:</label>
                            <select
                                id="clarity"
                                value={selectedClarity}
                                onChange={(e) => handleClarityChange(e.target.value)}
                                className="mt-1 block w-1/3 py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            >
                                {availableClarity.length > 0 ? (
                                    availableClarity.map((clarity) => (
                                        <option key={clarity} value={clarity}>{clarity}</option>
                                    ))
                                ) : (
                                    <option value="None">None</option>
                                )}
                            </select>
                        </div>

                        <div className="mt-4">
                            <label htmlFor="carat" className="block font-medium">Center Stone Weight (ct):</label>
                            <select
                                id="carat"
                                value={caratWeight}
                                onChange={(e) => handleCaratChange(e.target.value)}
                                className="mt-1 block w-1/3 py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            >
                                {availableCaratWeights.length > 0 ? (
                                    availableCaratWeights.map((weight) => (
                                        <option key={weight} value={weight}>{weight} ct</option>
                                    ))
                                ) : (
                                    <option value="0">0 ct</option>
                                )}
                            </select>
                        </div>

                        <div className="mt-4">
                            <label htmlFor="shape" className="block font-medium">Center Stone Shape:</label>
                            <select
                                id="shape"
                                value={stoneShape}
                                onChange={(e) => handleShapeChange(e.target.value)}
                                className="mt-1 block w-1/3 py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            >
                             {availableShapes.length > 0 ? (
                                availableShapes.map((shape) => (
                                    <option key={shape} value={shape}>{shape}</option>
                                ))
                             ) : (<option value="">None</option>
                             )}
                            </select>
                        </div>

                        <div className="mt-4 flex flex-row">
                            <div className="flex-col justify-evenly min-w-80">
                                <p>Total Carat Weight</p>
                                <span>0.44 ctw</span>
                            </div>
                            <div className="flex-col justify-evenly">
                                <p>Size</p>
                                <span>{ringSize}</span>
                            </div>
                        </div>

                        <div className="mt-4 flex flex-row">
                            <div className="flex-col justify-evenly min-w-80">
                                <p>Retail Price</p>
                                <span>${retailPrice}</span>
                            </div>
                            <div className="flex-col justify-evenly">
                                <p>Category</p>
                                <span>{category}</span>
                            </div>
                        </div>

                        <div className="mt-8">
                            <h3 className="text-lg font-medium">Companion Items</h3>
                            <div className="mt-2 border rounded-md p-4">
                                <img src="/path/to/companion-item.jpg" alt="Companion Item" className="w-24" />
                                <p className="mt-2">MR18650_34-18...</p>
                            </div>
                        </div>

                        <button className="shadow mt-4 bg-gradient-to-tr px-3 from-gray-700 to-gray-400 text-white py-1.5 font-[300] rounded-md">
                            Add to Cart
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductPage;