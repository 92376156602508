
import React, { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ReactPaginationStyle from 'src/Components/ReactPagination';
import SelectRows from 'src/Components/SelectRows';
import CardLoader from 'src/Components/CardLoader';
import { useDispatch, useSelector } from 'react-redux';
import { ACTION_HANLDE_CART_UPDATE } from 'src/Services/Constant/types';
import { GET_ATTCHMENT_DETAILS } from 'src/Services/Redux/Actions/orders';
import moment from 'moment'
import { AlertInfo } from 'src/Services';

export default function InoviceContent({ hooks }) {
    let {
        data, totalRecords, selectedRowDetails, setSelectedRowDetails, setImage_UniqueID,
        page, handleRows, rows, loader,
        handlePagination
    } = hooks;
    const dispatch = useDispatch();
    const carts = useSelector(state => state?.cartReducer?.carts)
    const [rowSelection, setRowSelection] = useState({});

    const columns = useMemo(
        () => [
            {
                accessorKey: 'INVOICEDATE',
                header: 'Invoice Date',
                filterVariant: 'range',
                Cell: ({ cell }) => {
                    return <span>{moment(new Date(cell?.getValue())).format('MM-DD-YYYY')}</span>;
                },
                size: 50,
            },
            {
                accessorKey: 'INVOICEID',
                header: 'Invoice ID',
                filterVariant: 'text',
            },
            {
                accessorKey: 'SALESID',
                header: 'Order',
                filterVariant: 'text',
            },
            {
                accessorKey: 'ITEMID',
                header: 'Style',
                filterVariant: 'text',
            },
            {
                accessorKey: 'TAGGEDCARATWT',
                header: 'Version',
                filterVariant: 'text',
                size: 50,
            },
            {
                accessorKey: 'CENTERSTONESHAPE',
                header: 'Shape',
                filterVariant: 'text',
                size: 50,
            },
            {
                accessorKey: 'CENTERSTONEWEIGHT',
                header: 'Center Size',
                filterVariant: 'text',
                size: 50,
            },
            {
                accessorKey: 'METALDESC',
                header: 'Metal',
                filterVariant: 'text',
                size: 50,
            },
            {
                accessorKey: 'PRODUCTSIZEID',
                header: 'Ring Size',
                filterVariant: 'text',
                size: 50,
            },
            {
                accessorKey: 'NAME',
                header: 'Customer Name',
                filterVariant: 'text',
            },
            {
                accessorKey: 'TRACKINGNUMBER',
                header: 'TRACKING NUMBER',
                filterVariant: 'text',
                Cell: ({ cell }) => {
                    const trackingNumber = cell?.getValue();
                    const trackingUrl = `http://www.fedex.com/Tracking?cntry_code=us&tracknumber_list=$20${trackingNumber}&language=english`;
                    return (
                        <a href={trackingUrl} target="_blank" rel="noopener noreferrer" style={{ color: 'gray' }}>
                            {trackingNumber}
                        </a>
                    );
                },
            },
            {
                accessorKey: 'SALESORDER_ARBSPECIALORDERFLAG',
                header: 'SA Type',
                filterVariant: 'text',
            }
        ],
        [],
    );

    const runSelectdRowLogic = () => {
        const debounceTimer = setTimeout(() => {
            let selectedAre = {}
            for (let id of carts?.ids) {
                selectedAre[id] = true
            }
            setRowSelection(selectedAre)
        }, 900)

        return () => {
            clearTimeout(debounceTimer)
        }
    }

    useEffect(() => {
        runSelectdRowLogic()
    }, [])

    const getSelectedRowDetail = (row) => {
        let payload = {
            INVENTORYLOTID: row?.INVENTORYLOTID
        }
        dispatch(GET_ATTCHMENT_DETAILS(payload))
        setSelectedRowDetails(row)
        setImage_UniqueID(row?.Image_UniqueID)
    }

    const uId = (row) => {
        return `${row?.INVENTORYLOTID}-${row?.INVOICEID}`
    }

    const table = useMaterialReactTable({
        columns,
        data,
        initialState: { density: 'compact' },
        enableStickyHeader: true,
        enableFullScreenToggle: true,
        renderBottomToolbar: false,
        enableRowSelection: true,
        getRowId: (row) => uId(row),
        state: { rowSelection },
        muiTableContainerProps: { sx: { maxHeight: '450px' } },

        muiTableBodyRowProps: ({ row }) => ({
            //add onClick to row to select upon clicking anywhere in the row
            onClick: () => getSelectedRowDetail(row?.original), // Corrected onClick handler
            sx: { cursor: 'pointer', backgroundColor: uId(row?.original) === uId(selectedRowDetails) ? '#e5e7eb' : '' },
            // className:'bg-red-200',
        }),

        renderTopToolbarCustomActions: () => (
            <div className='flex justify-start items-center space-x-4'>
                <ReactPaginationStyle
                    total={totalRecords}
                    currentPage={page}
                    handlePagination={(value) => {
                        handlePagination(value)
                        // clear select on page change
                        runSelectdRowLogic()
                    }}
                    limit={rows}
                />
                <SelectRows rows={rows} setRows={handleRows} />
            </div>
        ),

        onRowSelectionChange: setRowSelection,

    });

    useEffect(() => {
        let checkedItems = Object.keys(rowSelection)
        if (checkedItems?.length) {
            let allCartIds = carts?.ids
            let cartlist = carts?.data

            for (let rowKey of checkedItems) {
                if (!allCartIds?.includes(rowKey)) {
                    let newItem = data?.find(item => uId(item) === rowKey)
                    allCartIds.push(rowKey)
                    cartlist.push({ ...newItem, cartfrom: 'INVOICES' })
                } else {
                    AlertInfo('Item is already in cart')
                }

            }
            dispatch({
                type: ACTION_HANLDE_CART_UPDATE, data: {
                    data: cartlist,
                    ids: allCartIds
                }
            })
        }
    }, [rowSelection])


    return (
        <>
            {
                loader ? <CardLoader /> : <LocalizationProvider>
                    <MaterialReactTable
                        getRowId={(row) => console.log(row)}
                        table={table} />
                </LocalizationProvider>
            }
        </>

    );
}
