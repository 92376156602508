import { useSelector } from 'react-redux'

const Notes = () => {
    const getOrderAttachment = useSelector(state => state?.ordersReducer?.getOrderAttachment)
    console.log("getOrderAttachment from notes.js", getOrderAttachment)
    return (
        <>
            <div>
                <h6 className="font-[700] text-gray-600 ml-7">Notes:</h6>
                {Array.isArray(getOrderAttachment) ? (
                    getOrderAttachment.map((attachment, index) => (
                        <p className='ml-7' key={index}>{attachment.NOTESSTR}</p>
                    ))
                ) : (
                    <p>{getOrderAttachment?.NOTESSTR}</p>
                )}
            </div>
        </>
    )
}

export default Notes;