import InvoiceTable from './InvoiceTable'
import TotalCart from 'src/Components/TotalCart';
import ReOrder from 'src/Components/ReOrder';
import { GET_INVOICES, GET_EMAIL_INVOICES } from 'src/Services/Redux/Actions/invoices'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import moment from 'moment';
import Button from '@mui/material/Button'
import { AlertInfo } from 'src/Services';
// 
import DaysSelect from './daysSelect';
import BasicDetails from './BasicDetails';
import SearchByColumn from './searchByColumn';
import FilesListing from './filesListing';
import Notes from './notes'
import FilterModal from './filterModal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const InvoiceLayout = () => {
    const dispatch = useDispatch()
    const [tabis, setTabis] = useState('detail');
    const [day, setDay] = useState(120)
    const [rows, setRows] = useState(10)
    const [page, setPage] = useState(1)
    const [loader, setLoader] = useState(true)
    const [totalRecords, setTotalRecords] = useState(0)
    const [selectedRowDetails, setSelectedRowDetails] = useState(null);
    const [searchStr, setSearchStr] = useState('')
    const [filterField, setFilterFiled] = useState('')
    const [image_UniquesID, setImage_UniqueID] = useState(null)
    let userinfo = JSON.parse(localStorage.getItem('userinfo'))
    const invoicedOrders = useSelector(state => state?.InvoicesReducer?.invoices)
    
    const [apiPayload, setApiPayload] = useState({
        start_date: moment().subtract(4, 'M').format('MM-DD-YYYY'),
        end_date: moment(new Date()).format('MM-DD-YYYY'),
        pageno: 1,
        limit: 10,
        searchStr: "",
        searchColumn: "",
        totalRecords,
        email: userinfo?.email,
    })

    useEffect(() => {
        if (invoicedOrders?.pagination?.totalRecords) {
            setTotalRecords(invoicedOrders.pagination?.totalRecords)
        }
    }, [invoicedOrders])

    const ui_to_db = {
        "Invoice ID": "invoiceid",
        "Style": "ITEMID",
        "Shape": "CENTERSTONESHAPE",
        "Metal": "METALDESC",
        "Ring Size": "PRODUCTSIZEID",
        "Customer Name": "NAME",
        "SA Type": "SALESORDER_ARBSPECIALORDERFLAG",
        "Tracking No.": "TRACKINGNUMBER"
    }

    const fetchOrders = async () => {
        const formData = {
            ...apiPayload,
            searchStr,
            searchColumn: ui_to_db[filterField],
            email: userinfo?.email,
        };
        await dispatch(GET_INVOICES(formData))
        setLoader(false)
    }

    useEffect(() => {
        fetchOrders()
    }, [])

    const handledate = (name, value) => {
        if(value){
            setApiPayload({
                ...apiPayload,
                totalRecords: 0,
                [name]: moment(value).format('MM-DD-YYYY')
            })
        }
     
    }


    const handleDays = async (day) => {
        setLoader(true)
        setDay(day)
        let updatedpayload = {
            start_date: moment().subtract((day / 30), 'M').format('MM-DD-YYYY'),
            end_date: moment(new Date()).format('MM-DD-YYYY'),
            pageno: 1,
            limit: 10,
            searchStr: "",
            searchColumn: "",
            totalRecords:0,
            email: userinfo?.email,
        }
        setApiPayload(updatedpayload)
        await dispatch(GET_INVOICES(updatedpayload))
        setLoader(false)
    }

    const handlePagination = async (p) => {
        setPage(p)
        const data = {
            ...apiPayload,
            pageno: p || 1,
            totalRecords,
            email: userinfo?.email,
        };
        await dispatch(GET_INVOICES(data))
        setApiPayload(data)
    }

    const handleRows = async (r) => {
        setRows(r)
        setLoader(true)
        const data = {
            ...apiPayload,
            limit: r,
            totalRecords,
            email: userinfo?.email,
        };
        await dispatch(GET_INVOICES(data))
        setLoader(false)
        setApiPayload(data)
    }

    const sendEmailInvoice = async () => {
        console.log("Function",selectedRowDetails.SALESID);
        if (!selectedRowDetails) {
            alert("Please select a row to email the invoice.");
            return;
        }

        const { invoiceaccount, INVOICEID, SALESID } = selectedRowDetails;

        if (!invoiceaccount || !INVOICEID || !SALESID) {
            alert("Selected row details are incomplete. Please check the data.");
            return;
        }
    
        try {
            setLoader(true);
            // const response = await fetch('/api/send-invoice-email', {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify(selectedRowDetails),
            // });
            const payload = {
                account_number: invoiceaccount,
                email: userinfo?.email,
                invoice_number: INVOICEID,
                salesorder_number: SALESID,
            };

            const response = await dispatch(GET_EMAIL_INVOICES(payload))

            console.log("Response:", response)
    
            if (response.success == true) {
                // alert("Invoice emailed successfully!");
                alert("Invoice copy request submitted, you will receive a copy of your invoice in 10 to 15 minutes")
            } else {
                alert("Failed to email the invoice. Please try again.");
            }
        } catch (error) {
            console.error("Error sending email invoice:", error);
            alert("An error occurred while sending the email.");
        } finally {
            setLoader(false);
        }
    };

    useEffect(() => {
        console.log('selectedRowDetails', selectedRowDetails)
    }, [selectedRowDetails])

    return (
        <div className='w-full'>
            <div className='w-full flex justify-between items-center my-2 pr-2 '>
                <h2 className='text-gray-800 font-[600] text-lg'>Invoice</h2>
                <div className='flex justify-start items-center space-x-2'>
                    <ReOrder />
                    <TotalCart />
                    <Button
                        onClick={async () => await sendEmailInvoice()} // Use an async function here
                        disabled={!selectedRowDetails}
                        className={`bg-gradient-to-tr from-gray-700 to-gray-400 text-white py-1.5 px-3 font-[300] rounded-lg ${
                            !selectedRowDetails ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                    >
                        Request Invoice Copy
                    </Button>
                </div>
            </div>
            <div className='my-2 w-full flex justify-between items-center '>
                {/* Below ui for large Screen */}
                <div className='hidden lg:block'>
                    <div id='filterTab' className='my-2 flex justify-start items-center space-x-2'>
                        <SearchByColumn
                            hooks={{
                                fetchOrders,
                                searchStr,
                                setSearchStr,
                                filterField,
                                setFilterFiled,
                                selectWidth: '160px'
                            }}
                        />
                        <DaysSelect
                            hooks={{ day, handleDays }}
                            id='select-last-days-filter'
                        />
                        
                        <DatePicker
                            className='h-10 rounded-md px-2' selected={apiPayload?.start_date || ''}
                            name='start_date' onChange={(date) => handledate('start_date', date)}
                            // dateFormat="MM-DD-YYYY"
                        />
                        <DatePicker
                            className='h-10 rounded-md px-2' selected={apiPayload?.end_date || ''}
                            name='end_date' onChange={(date) => handledate('end_date', date)}
                            // dateFormat="MM-DD-YYYY"
                        />
                        
                        <Button
                            onClick={() => fetchOrders()}
                            className=' bg-gradient-to-tr  from-gray-700 to-gray-400 text-white py-1.5 px-3 font-[300] rounded-lg'>
                            Filter
                        </Button>
                    </div>
                </div>

                <div className='block lg:hidden'>
                    <FilterModal
                        hooks={{
                            fetchOrders,
                            searchStr,
                            setSearchStr,
                            filterField,
                            setFilterFiled,
                            apiPayload,
                            handleDays,
                            handledate,
                            day
                        }}
                    />
                </div>

            </div>
            <>
                <div className='w-full mb-2'>
                    <InvoiceTable
                        hooks={{
                            loader,
                            handleRows,
                            handlePagination,
                            rows, page,
                            selectedRowDetails,
                            setSelectedRowDetails,
                            setImage_UniqueID,
                            totalRecords,
                            data: invoicedOrders?.list
                        }}
                    />
                </div>
                {selectedRowDetails && <div className='bg-white rounded-md shadow min-h-64'>
                    <Tabs
                        value={tabis}
                        onChange={(e, data) => setTabis(data)} aria-label="lab API tabs example">
                        <Tab label="Invoice Details" value={'detail'} />
                        <Tab label="Files" value={'files'} />
                        <Tab label="Notes" value={'notes'} />
                    </Tabs>

                    {tabis === 'detail' && <>
                        {selectedRowDetails && <BasicDetails
                            rowData={selectedRowDetails} image_UniquesID={image_UniquesID} />}
                    </>}

                    {tabis === 'files' && <FilesListing />}

                    {tabis === 'notes' && <Notes />}

                </div>}
            </>
        </div>
    )
}

export default InvoiceLayout;