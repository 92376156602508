import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginScreen from "src/Pages/Login";
import RouteLayout from "./routeLayout";
import OrdersPage from "src/Pages/loggedIn/Orders";
// import Portel from '../portal'
import Error404Page from "src/Pages/Error404";
import InvoicePage from "src/Pages/loggedIn/Invoice";
import CreateOrder from "src/Pages/loggedIn/createOrder";
import DashboardPage from "src/Pages/loggedIn/dashboard";
import WebOrdersPage from "src/Pages/loggedIn/webOrder";
import ArchiveOrdersPage from "../Pages/loggedIn/ArchiveOrders"
import ChatBot from "src/Pages/loggedIn/chatbot";
import AdminUser from "src/Pages/loggedIn/adminUser"
import Catalogue from "src/Pages/loggedIn/catalogue"
import Ring_Configurator from "src/Pages/loggedIn/ringConfigurator"

const AllRoutes = () => {

    return <Router>
        <Routes>
            <Route path="/login" element={<LoginScreen />} />
            <Route element={<RouteLayout />}>
                <Route path="/" element={<OrdersPage />} />
                <Route path="/app/order" element={<OrdersPage />} />
                <Route path="/app/dashboard" element={<DashboardPage />} />
                <Route path="/app/invoice" element={<InvoicePage />} />
                <Route path="/app/create-order" element={<CreateOrder />} />
                <Route path="/app/web-orders" element={<WebOrdersPage />} />
                <Route path="/app/archived-invoices" element={<ArchiveOrdersPage />} />
                <Route path="/app/chatbot" element={<ChatBot />} />
                <Route path="/app/admin-user" element={<AdminUser />} />,
                <Route path="/app/catalogue" element={<Catalogue />} />
                <Route path="/app/ring-configration" element={<Ring_Configurator />} />
                <Route path="*" element={<Error404Page />} />
            </Route>
        </Routes>
    </Router>
}


export default AllRoutes;