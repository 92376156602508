// import { AlertError, AlertSuccess } from "src/Services"
import { postMethodApiCall } from "src/Services/API"
import { ACTION_GET_CUSTOMER_DETAILS, ACTION_GET_SHIP_ACCOUNTS } from "src/Services/Constant/types"

const baseUrl = process.env.REACT_APP_BASE_URL

const getHeadersWithoutAuth = () => {
    return {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('token')}`
    }
}

export const GET_CUSTOMER_DETAILS = data => {
    return async dispatch => {
        try {
            const url = `${baseUrl}/odata/get/knowCustomer`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                // dispatch({ type: ACTION_GET_WEBORDERS, data: { list: res?.data?.sales_line_rows, pagination: res?.data?.pagination } || { list: 0, pagination: {} } })
                dispatch({type: ACTION_GET_CUSTOMER_DETAILS, data:{list: res?.data?.rows} || {list: 0}})
                return res?.data?.rows
            } else {
                return { success: false, message: res?.data?.message }
            }
        } catch (error) {
            console.log((error?.response?.data).replace(/\\/g, ""))
        }
    }
}

export const GET_SHIP_ACCOUNTS = data => {
    return async dispatch => {
        try {
            const url = `${baseUrl}/odata/get/searchShipAccount`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                // const customerAccounts = res?.data?.rows.map(row => row.CUSTOMERACCOUNT);
                // const customerNames = res?.data?.rows.map(row => row.ORGANIZATIONNAME);
                // dispatch({type: ACTION_GET_SHIP_ACCOUNTS, data:{list: customerAccounts}})
                return res?.data
            } else {
                return { success: false, message: res?.data?.message }
            }
        } catch (error) {
            console.log((error?.response?.data).replace(/\\/g, ""))
        }
    }
}