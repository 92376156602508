import moment from "moment"
import { postMethodApiCall } from "src/Services/API"
import { ACTION_AJAFFE_JEWELLERY_DATA, ACTION_B2B_ITEM_DATA } from "src/Services/Constant/types"

const baseUrl = process.env.REACT_APP_BASE_URL

const getHeadersWithoutAuth = () => {
    return {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('token')}`
    }
}

export const GET_AJAFFE_JEWELLERY_DATA = data => {
    return async dispatch => {
        try {
            let payload = {
                requirement : "Send Data",
                ...data,
            }
            const url = `${baseUrl}/odata/get/ajaffeJewelData`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                console.log("DATA FOUND:", res)
                dispatch({ type: ACTION_AJAFFE_JEWELLERY_DATA, data: { list: res?.data?.rows, pagination: res?.data?.pagination } || { list: 0, pagination: {}} })
            } else {
                return { success: false, message: res?.data?.message }
            }
        } catch (error) {
            console.log((error?.response?.data).replace(/\\/g, ""))
        }
    }
}

export const GET_B2B_ITEM_DATA = data => {
    return async dispatch => {
        try {
            const url = `${baseUrl}/odata/get/B2B_Items_Metals`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                console.log("DATA FOUND:", res)
                dispatch({ type: ACTION_B2B_ITEM_DATA, data: { list: res?.data?.Metals} || { list: 0} })
            } else {
                return { success: false, message: res?.data?.message }
            }
        } catch (error) {
            // console.log((error?.response?.data).replace(/\\/g, ""))
            console.log("ERROR: ", error)
        }
    }
}