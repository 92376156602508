import orderBoxImage from 'src/Assets/Images/order-box.png'
import ImageWithFallbackAndLoader from 'src/Components/ImageLoader';

const BasicDetails = ({ rowData, image_UniquesID }) => {
    return (
        <div className="shadow-md bg-white rounded-md p-2 mt-2">
            <div className="grid grid-cols-1 gap-4 mt-4 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-1">
                <div className='w-full'>
                    <ImageWithFallbackAndLoader
                        className=' w-full h-52 object-contain '
                        fallbackSrc={orderBoxImage}
                        src={`https://lh3.googleusercontent.com/d/${image_UniquesID}`}
                        alt={'image link'}
                    />
                </div>
                <div>
                    <div>
                        <h6 className=" font-[700] text-gray-600">Invoice Account:</h6>
                        <p> {rowData?.invoiceaccount}</p>
                    </div>
                    <div>
                        <h6 className=" font-[700] text-gray-600 mt-14">Invoice Header Amount:</h6>
                        <p> {rowData?.invoiceheaderamount}</p>
                    </div>
                    <div>
                        <h6 className=" font-[700] text-gray-600 mt-14">Invoice Header Salses ID:</h6>
                        <p> {rowData?.invoiceheadersalesid}</p>
                    </div>
                </div>
                <div>
                    <div>
                        <h6 className=" font-[700] text-gray-600">Invoice Street Number:</h6>
                        <p> {rowData?.invoicestreetnumber}</p>
                    </div>
                    <div>
                        <h6 className=" font-[700] text-gray-600 mt-14">Invoice Street:</h6>
                        <p> {rowData?.invoicestreet}</p>
                    </div>
                    <div>
                        <h6 className=" font-[700] text-gray-600 mt-14">Invoice City:</h6>
                        <p> {rowData?.invoicecity}</p>
                    </div>
                </div>
                <div>
                    <div>
                        <h6 className=" font-[700] text-gray-600">Invoice State:</h6>
                        <p> {rowData?.invoicestate}</p>
                    </div>
                    <div>
                        <h6 className=" font-[700] text-gray-600 mt-14">Invoice Zip Code:</h6>
                        <p> {rowData?.invzipcode}</p>
                    </div>
                    <div>
                        <h6 className=" font-[700] text-gray-600 mt-14">SA Type:</h6>
                        <p> {rowData?.SALESORDER_ARBSPECIALORDERFLAG}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BasicDetails;