import moment from "moment"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"


const SalesPreview = () => {
    const orders = useSelector(state => state?.ordersReducer?.orders)

    return (
        <div className="p-2 w-full shadow bg-white rounded">
            <div className="flex justify-between items-center">
                <h6 className="text-gray-800 font-bold">Order Of last 30 Days</h6>
                <Link to='/app/order' className="border px-2 text-sm rounded bg-gray-100 text-gray-500">
                    View All
                </Link>
            </div>
            <div className="w-full h-[calc(100%)] overflow-y-scroll">
                <table className="w-full mt-2">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="border px-1">Date</th>
                            <th className="border px-1">Order</th>
                            <th className="border px-1">Style</th>
                            <th className="border px-1">Version</th>
                            <th className="border px-1">Metal</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            orders?.list?.map((item, i) => {
                                return <tr key={i} className="hover:bg-gray-50 text-sm ">
                                    <td className="border px-1">{moment(new Date(item?.ORDERCREATIONDATETIME)).format('MM-DD-YYYY')}</td>
                                    <td className="border px-1">{item?.SALESORDERNUMBER}</td>
                                    <td className="border px-1">{item?.ITEMNUMBER}</td>
                                    <td className="border px-1">{item?.TAGGEDCARATWT}</td>
                                    <td className="border px-1">{item?.METALDESC}</td>
                                </tr>
                            })
                        }
                        
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default SalesPreview