import {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GET_IMAGE } from 'src/Services/Redux/Actions/orders';

const RowDetails = ({ data, handleChange, imageLink }) => {
    // const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    // console.log("rowDetail IMAGE: ", imageLink, "---")
    // console.table(typeof(imageLink))
    const [imageURL, setImageURL] = useState(null);
    useEffect(() => {
        setImageURL(imageLink);
    }, [imageLink]);

    // useEffect(() => {
    //     console.log("Image URL from row detail: ", imageURL);
    // }, [imageURL]);

    useEffect(() => {  // Use another useEffect to update isLoading
        if (imageURL) {
            setIsLoading(false);
            console.log("Image URL from row detail: ", imageURL);
        }
    }, [imageURL]);

    // const ImageURL = useSelector(state => state?.ordersReducer?.getImageLinkFromStyle)

    // const fetchImage = async () => {
    //     const formData = {
    //         styleid: data?.Style
    //     };
    //     await dispatch(GET_IMAGE(formData))
    // }

    // useEffect(() => {
    //     fetchImage()
    // }, [data])

    // useEffect(() => {
    //     console.log("IMage URL from reducer: ", ImageURL)
    // }, [ImageURL])

    return <div className="text-sm mt-4 w-full grid grid-cols-1 gap-2 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1">
        <div>
            <label>Center Shape</label>
            <br />
            <input
                id={data?.uId}
                value={data?.CenterShape || ''}
                onChange={handleChange}
                name="CenterShape"
                className="border w-full focus:ring-0 outline-none  placeholder:text-gray-900 p-1.5 px-2 rounded-md " />
        </div>
        <div>
            <label>Center Millimeter</label>
            <br />
            <input
                id={data?.uId}
                value={data?.CenterStoneLength || ''}
                onChange={handleChange}
                name="CenterStoneLength"
                className="border w-full focus:ring-0 outline-none  placeholder:text-gray-900 p-1.5 px-2 rounded-md " />
        </div>
        {/* <div>
            <label>Center Stone Width</label>
            <br />
            <input
                id={data?.uId}
                value={data?.CenterStoneWidth }
                onChange={handleChange}
                name="CenterStoneWidth"
                type='text' className="border w-full focus:ring-0 outline-none  placeholder:text-gray-900 p-1.5 px-2 rounded-md " />
        </div> */}
        <div>
            <label>Two Tone</label>
            <br />
            <select
                id={data?.uId}
                value={data?.TwoTone || ''}
                onChange={handleChange}
                name="TwoTone"
                className="border w-full focus:ring-0 outline-none  placeholder:text-gray-900 p-1.5 px-2 rounded-md">
                <option value="" disabled>Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </select>
        </div>
        <div>
            <label>Image Preview</label>
            <br />
            {isLoading ? (
                <div>Loading...</div>
            ) : (
                <img
                    src={imageURL}
                    alt="Preview"
                    className="w-full h-full"
                />
            )}
        </div>
    </div>
}

export default RowDetails;