import { ACTION_GET_ATTACHMENT_DETAILS, ACTION_GET_ORDERS, ACTION_GET_ORDERS_DETAIL_BY_ID, ACTION_GET_STYLE_VERSIONS, ACTION_GET_VERSION, ACTION_GET_IMAGE_LINK_FROM_STYLE } from "src/Services/Constant/types"

// **  Initial State
const initialState = {
    orders: { list: [], pagination: {} },
    orderDetail: {},
    versions: [],
    getStyleVersion: null,
    getOrderAttachment: [],
    getImageLinkFromStyle: null
}

const ordersReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_GET_ORDERS:
            return {
                ...state,
                orders: action.data
            }
        case ACTION_GET_ORDERS_DETAIL_BY_ID:
            return {
                ...state,
                orderDetail: action.data
            }
        case ACTION_GET_VERSION:
            return {
                ...state,
                versions: action.data
            }
        case ACTION_GET_STYLE_VERSIONS:
            return {
                ...state,
                getStyleVersion: action.data
            }
        case ACTION_GET_ATTACHMENT_DETAILS:
            return {
                ...state,
                getOrderAttachment: action.data
            }
        case ACTION_GET_IMAGE_LINK_FROM_STYLE:
            return {
                ...state,
                getImageLinkFromStyle: action.data
            }
        default:
            return state
    }
}

export default ordersReducer