import React, { useEffect, useState } from "react";
import { defaultItemRow } from "./defaultItemData";
import { GET_IMAGE_LINK_BY_FILE, GET_STYLE_VERSIONS, GET_IMAGE } from "src/Services/Redux/Actions/orders";
import { useDispatch, useSelector } from 'react-redux'
import VersionDropdown from "./versionDropdown";
import ItemDetailTab from "./itemDetailTab";
import { AlertError, AlertInfo } from "src/Services";
import { ACTION_HANLDE_CART_UPDATE } from "src/Services/Constant/types";

// const cols = [
//     'Style', 'ConfigID', 'BatchID', 'Version', 'Center Size', 'Center', 'Customer Center', 'Metal', 'Quality',
//     'Center Quality', 'Ring Size', 'Qty', ''
// ]

const cols = [
    'Style', 'ConfigID', 'BatchID', 'Version', 'Center Size', 'Center', 'Center Quality', 'Metal', 'Quality',
    'Ring Size', 'Qty', ''
]
const metal_types = ["14K Gold", "14K Rose", "14K White", "14K Yellow", "18K Rose", "18K White", "18K Yellow", "18K Gold", "Platinum"];
// const quality_types = ["GH VS2", "GH SI", "GH SI2", "GH SI3", "CZ"];
const quality_types = ["G/VS", "G/SI"];

const ItemTable = ({ items, setItems, OrderNo }) => {
    const dispatch = useDispatch()
    const [fileUploadLoader, setFileUploadLoader] = useState('')
    const [selectedRow, setSelectedRow] = useState(null)
    const carts = useSelector(state => state?.cartReducer?.carts)
    const [imageLink, setImageLink] = useState(null);

    const handleChange = (e) => {
        let { id, value, name } = e.target
        let newData = items?.map(it => {
            if (name === "Center" && value === "No" && it["CenterQuality"] !== '') {
                it["CenterQuality"] = ''
            }
            if (name === "CustomerCenter" && value === "Yes" && it["CenterQuality"] !== '') {
                it["CenterQuality"] = ''
            }
            if (it?.uId === id) {
                it[name] = value
            }
            return it
        })
        setItems([...newData])
    }

    const handleStyleChange = (e) => {
        let { id, value, name } = e.target
        let newData = items?.map(it => {
            if (it?.uId === id) {
                it[name] = value
            }
            try {
                dispatch(GET_IMAGE({ Style: value })).then(imageData => {
                    if (imageData && imageData.length > 0 && imageData[0].Image_URL) {
                        console.log("IMAGE ITEMTABLE:", imageData[0].Image_URL);
                        setImageLink(imageData[0].Image_URL);
                    }
                })
            } catch (error) {
                console.log("Error in handleStylechange");
            }
            return it
        })
        setItems([...newData])
    }

    // const handleStyleChange = async (e) => {
    //     let { id, value, name } = e.target;
    
    //     try {
    //         const imageData = await dispatch(GET_IMAGE({ Style: value }));
    
    //         if (imageData && imageData.length > 0 && imageData[0].Image_URL) {
    //             console.log("IMAGE ITEMTABLE:", imageData[0].Image_URL);
    //             setImageLink(imageData[0].Image_URL);
    //         } else {
    //             // Handle the case where imageData is undefined, empty, or doesn't have Image_URL
    //             console.warn("Image data not found or invalid:", imageData);
    //             // Optionally, set a default image link or handle the error in another way.
    //             // setImageLink(defaultImageLink);
    //         }
    
    
    //         const newData = items?.map(it => {
    //             if (it?.uId === id) {
    //                 it[name] = value;
    //             }
    //             return it;
    //         });
    //         setItems([...newData]);
    
    //     } catch (error) {
    //         console.error("Error fetching image data:", error);
    //         // Handle the error appropriately, e.g., display an error message to the user.
    //     }
    // };

    // const handleStyleChange = async (e) => {
    //     let { id, value, name } = e.target;
    //     let newImageLink = null;
    //     try {
    //         const imageData = await dispatch(GET_IMAGE({ Style: value }));
    //         if (imageData && imageData.length > 0 && imageData[0].Image_URL) {
    //             newImageLink = imageData[0].Image_URL;
    //         }
    //     } catch (error) {
    //         console.error("Error fetching image data:", error);
    //     }
    
    //     const newData = items?.map(it => {
    //         if (it?.uId === id) {
    //             it[name] = value;
    //         }
    //         return it;
    //     });
    
    //     setItems(prevItems => { // Use functional form of setState for setItems
    //         const updatedItems = prevItems.map(item => 
    //            item.uId === id ? {...item, Style: value, imageUrl: newImageLink} : item 
    //         );
    //         return updatedItems;
    //     });
    //     setImageLink(newImageLink);
    
    //     // The setImageLink update is now incorporated in the setItems update.
    //     // setImageLink(newImageLink); <--- REMOVE THIS
    // };

    const AddRow = () => {
        setItems(prevList => [
            ...prevList,
            { ...defaultItemRow(), line_number: prevList?.length + 1 }
        ])
    }

    const getCartUID = (row) => {
        if (row?.cartfrom === 'ARCHIVED') return `${row?.INVENTTRANSID}-${row?.INVOICEID}`
        else if (row?.cartfrom === 'INVOICES') return `${row?.INVENTORYLOTID}-${row?.INVOICEID}`
        else if (row?.cartfrom === 'ORDER') return row?.inventorylotid
        else if (row?.cartfrom === 'WEB_ORDER') return `${row?.WebOrderID}-${row?.line_number}`
    }

    const removeRow = (uid, cartUid) => {
        if (items?.length === 1) AlertError("Last row is not allowed to delete!")
        if (uid === selectedRow?.uId) setSelectedRow(null)
        let rows = items?.filter(i => i?.uId !== uid)
        if (cartUid) {
            let cartrow = carts?.data?.filter(row => getCartUID(row) !== cartUid)
            let cartids = carts?.ids?.filter(id => id !== cartUid)
            dispatch({
                type: ACTION_HANLDE_CART_UPDATE, data: {
                    data: cartrow,
                    ids: cartids
                }
            })
            AlertInfo('Item also removed from cart')
        }
        setItems(rows)
    }

    const handleVersionSelect = async (verison, rowUID) => {
        let newData = []
        for (let it of items) {
            if (it?.uId === rowUID) {
                it['Version'] = verison?.TAGGEDCARATWT
                if (verison?.TAGGEDCARATWT) {
                    let data = {
                        Style: it?.Style,
                        Version: verison?.TAGGEDCARATWT
                    }
                    let res = await dispatch(GET_STYLE_VERSIONS(data))
                    it['CenterShape'] = res?.CENTERSTONESHAPE
                    it['CenterQuality'] = res?.CENTERSTONEQUALITY
                    it['CenterStoneWidth'] = res?.CENTERSTONEWIDTH
                    it['CenterSize'] = res?.CENTERSTONEWEIGHT
                } else {
                    it['CenterShape'] = ''
                    it['CenterQuality'] = ''
                    it['CenterStoneWidth'] = ''
                    it['CenterSize'] = ''
                }
            }
            newData.push(it)
        }

        setItems(newData)
    }

    const handleFileAdd = async (e, uid, action, line_number) => {
        if (action === 'Add') {
            let newData = items?.map(it => {
                if (it?.uId === uid) {
                    it['ImageLinks'].push({
                        line_number: it['ImageLinks']?.length + 1,
                        file_path: "",
                        file_name: "",
                        file_type: "",
                        uId: Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36),
                    })
                }
                return it
            })
            setItems(newData)
        } else if (action === 'uploaded') {
            let { files, id } = e.target
            setFileUploadLoader(id)
            await dispatch(GET_IMAGE_LINK_BY_FILE(files[0], line_number, OrderNo)).then(res => {
                let newData = items?.map(it => {
                    if (it?.uId === uid) {
                        let images = []
                        for (let fileRowIs of it?.ImageLinks) {
                            if (fileRowIs?.uId === id.replace('-file-upload', '')) {
                                if (res?.success) {
                                    fileRowIs['file_path'] = res?.link
                                }
                                fileRowIs['file_name'] = files[0]?.name
                                fileRowIs['file_type'] = files[0]?.type
                                fileRowIs['file'] = files[0]
                                fileRowIs['base64path'] = files?.length ? URL.createObjectURL(files[0]) : ''
                            }
                            images.push(fileRowIs)
                        }
                        it['ImageLinks'] = images

                        setSelectedRow(it)
                    }
                    return it
                })
                setItems(newData)
                setFileUploadLoader('')
            })
        } else if (action === 'remove') {
            let newData = items?.map(it => {
                if (it?.uId === uid) {
                    if (it['ImageLinks']?.length === 1) {
                        AlertError('Can not remove last row ')
                        return it
                    }
                    it['ImageLinks'] = it['ImageLinks']?.filter(fileRowIs => fileRowIs?.uId !== e?.uId)
                }
                return it
            })
            setItems(newData)
        } else if (action === 'file_type') {
            let { value, id } = e.target
            let newData = items?.map(it => {
                if (it?.uId === uid) {
                    it['ImageLinks'] = it?.ImageLinks.map(fileRowIs => {
                        if (fileRowIs?.uId === id) {
                            fileRowIs['file_type'] = value
                        }
                        return fileRowIs
                    })
                }
                return it
            })
            setItems(newData)
        }
    }

    // useEffect(() => {
    //     const currentRow = items?.find(it => it.uId === rowUID);
    //     if (currentRow?.Style?.length >= 3) {
    //         dispatch(GET_IMAGE({ Style: currentRow.Style })).then(imageData => {
    //             if (imageData) {
    //                 let newData = items?.map(it => {
    //                     if (it?.uId === rowUID) {
    //                         it['imageUrl'] = imageData.url
    //                     }
    //                     return it
    //                 })
    //                 setImageLink(imageData.url);
    //             }
    //         })
    //     }
    // }, [rowUID, items?.find(it => it.uId === rowUID)?.Style])


    //     return (
    //         <div className="w-full min-h-60 shadow rounded-md mt-3 p-2 bg-white">
    //             <h4 className="my-1 font-[600]">Item Details</h4>
    //             <div className="w-full pb-60 min-h-56 overflow-scroll">
    //                 <table className='text-sm w-full relative'>
    //                     <thead>
    //                         <tr className='text-left capitalize bg-gray-100'>
    //                             {cols?.map((col) => (
    //                                 <th
    //                                     key={col}
    //                                     title={col}
    //                                     scope='col'
    //                                     className='font-[500] p-2 border text-start'
    //                                 >
    //                                     {col}
    //                                 </th>
    //                             ))}
    //                         </tr>
    //                     </thead>
    //                     <tbody>
    //                         {
    //                             items?.map(item => {
    //                                 return (
    //                                     <tr
    //                                         key={item?.uId}
    //                                         className={`cursor-pointer text-left ${selectedRow?.uId === item?.uId ? 'bg-gray-100 shadow-md' : 'bg-white'}`}
    //                                     >
    //                                         <td role="button" onClick={() => setSelectedRow(item)} className='border p-1'>
    //                                             <input
    //                                                 id={item?.uId}
    //                                                 name="Style"
    //                                                 onChange={handleChange}
    //                                                 value={item?.Style}
    //                                                 className=" uppercase placeholder:font-[300] placeholder:capitalize outline-none w-full bg-transparent " placeholder="Search" />
    //                                         </td>
    //                                         <td role="button" onClick={() => setSelectedRow(item)} className='border p-1'>
    //                                             <input
    //                                                 id={item?.uId}
    //                                                 name="ConfigID"
    //                                                 onChange={handleChange}
    //                                                 className=" uppercase placeholder:font-[300] placeholder:capitalize outline-none w-full bg-transparent " placeholder="Search" />
    //                                         </td>
    //                                         <td role="button" onClick={() => setSelectedRow(item)} className='border p-1'>
    //                                             <input
    //                                                 id={item?.uId}
    //                                                 name="BatchID"
    //                                                 onChange={handleChange}
    //                                                 className=" uppercase placeholder:font-[300] placeholder:capitalize outline-none w-full bg-transparent " placeholder="Search" />
    //                                         </td>
    //                                         <td role="button" onClick={() => setSelectedRow(item)} className='border p-1'>
    //                                             <VersionDropdown data={item?.listOfVersions}
    //                                                 rowUID={item?.uId}
    //                                                 row={item}
    //                                                 setItems={setItems}
    //                                                 items={items}
    //                                                 handleVersionSelect={handleVersionSelect}
    //                                                 version={item?.Version} />
    //                                         </td>
    //                                         <td role="button" onClick={() => setSelectedRow(item)} className='border p-1'>
    //                                             <input
    //                                                 id={item?.uId}
    //                                                 name="CenterSize"
    //                                                 onChange={handleChange}
    //                                                 value={item?.CenterSize}
    //                                                 disabled={item?.Center === "No"}
    //                                                 className=" uppercase placeholder:font-[300] placeholder:capitalize outline-none w-full bg-transparent " placeholder="Size" />
    //                                         </td>
    //                                         <td role="button" onClick={() => setSelectedRow(item)} className='border p-1'>
    //                                             <select
    //                                                 value={item?.Center}
    //                                                 onChange={handleChange}
    //                                                 name="Center"
    //                                                 id={item?.uId}
    //                                                 className="outline-none w-full bg-transparent">
    //                                                 <option value="" disabled>Select</option>
    //                                                 <option value="Yes">Yes</option>
    //                                                 <option value="No">No</option>
    //                                             </select>
    //                                         </td>
    //                                         <td role="button" onClick={() => setSelectedRow(item)} className='border p-1'>
    //                                             <select value={item?.CustomerCenter || ''}
    //                                                 onChange={handleChange}
    //                                                 name="CustomerCenter"
    //                                                 id={item?.uId}
    //                                                 className="outline-none w-full bg-transparent">
    //                                                 <option value="" disabled>Select</option>
    //                                                 <option value="Yes">Yes</option>
    //                                                 <option value="No">No</option>
    //                                             </select>
    //                                         </td>
    //                                         <td role="button" onClick={() => setSelectedRow(item)} className='border p-1'>
    //                                             <select value={item?.Metal || ''}
    //                                                 onChange={handleChange}
    //                                                 name="Metal"
    //                                                 id={item?.uId}
    //                                                 className="outline-none w-full bg-transparent">
    //                                                 <option value="" disabled>Select</option>
    //                                                 {
    //                                                     metal_types?.map((it => {
    //                                                         return <option key={it} value={it}>{it}</option>
    //                                                     }))
    //                                                 }
    //                                             </select>
    //                                         </td>
    //                                         <td role="button" onClick={() => setSelectedRow(item)} className='border p-1'>
    //                                             <select value={item?.Quality || ''}
    //                                                 onChange={handleChange}
    //                                                 name="Quality"
    //                                                 disabled={item?.CustomerCenter == "Yes"}
    //                                                 id={item?.uId}
    //                                                 className="outline-none w-full bg-transparent">
    //                                                 <option value="" disabled>Select</option>
    //                                                 {
    //                                                     quality_types?.map((it => {
    //                                                         return <option key={it} value={it}>{it}</option>
    //                                                     }))
    //                                                 }
    //                                             </select>
    //                                         </td>
    //                                         <td role="button" onClick={() => setSelectedRow(item)} className='border p-1'>
    //                                             <select value={item?.CenterQuality || ''}
    //                                                 onChange={handleChange}
    //                                                 id={item?.uId}
    //                                                 disabled={item?.Center === "No"}
    //                                                 // value={item?.Center == "No" then ''}
    //                                                 name="CenterQuality"
    //                                                 className="outline-none w-full bg-transparent">
    //                                                 <option value="" disabled>Select</option>
    //                                                 {
    //                                                     quality_types?.map((it => {
    //                                                         return <option key={it} value={it}>{it}</option>
    //                                                     }))
    //                                                 }
    //                                             </select>
    //                                         </td>
    //                                         <td role="button" onClick={() => setSelectedRow(item)} className='border p-1'>
    //                                             <input
    //                                                 onChange={handleChange}
    //                                                 name="RingSize"
    //                                                 id={item?.uId}
    //                                                 value={item?.RingSize}
    //                                                 className="outline-none w-full bg-transparent " placeholder="" />
    //                                         </td>
    //                                         <td role="button" onClick={() => setSelectedRow(item)} className='border p-1 w-20'>
    //                                             <input
    //                                                 id={item?.uId}
    //                                                 onChange={handleChange}
    //                                                 name="Quantity"
    //                                                 value={item?.Quantity || 1}
    //                                                 className="outline-none bg-transparent " placeholder="" />
    //                                         </td>
    //                                         <td className='border p-1'>
    //                                             <div className="flex justify-start space-x-2">
    //                                                 <span
    //                                                     onClick={() => removeRow(item?.uId, getCartUID(item))}
    //                                                     title='Delete'
    //                                                     role="button"
    //                                                     className='bg-gray-500 p-1 disabled:bg-gray-500/20 rounded-full text-white'
    //                                                 >
    //                                                     <svg
    //                                                         xmlns='http://www.w3.org/2000/svg'
    //                                                         viewBox='0 0 24 24'
    //                                                         fill='currentColor'
    //                                                         className='w-4 h-4'
    //                                                     >
    //                                                         <path
    //                                                             fillRule='evenodd'
    //                                                             d='M3.75 12a.75.75 0 01.75-.75h15a.75.75 0 010 1.5h-15a.75.75 0 01-.75-.75z'
    //                                                             clipRule='evenodd'
    //                                                         />
    //                                                     </svg>
    //                                                 </span>
    //                                                 <span
    //                                                     title='Add'
    //                                                     role="button"
    //                                                     onClick={AddRow}
    //                                                     className='bg-gray-500 p-1 disabled:bg-gray-500/20 rounded-full text-white'
    //                                                 >
    //                                                     <svg
    //                                                         xmlns='http://www.w3.org/2000/svg'
    //                                                         viewBox='0 0 24 24'
    //                                                         fill='currentColor'
    //                                                         className='w-4 h-4'
    //                                                     >
    //                                                         <path
    //                                                             fillRule='evenodd'
    //                                                             d='M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z'
    //                                                             clipRule='evenodd'
    //                                                         />
    //                                                     </svg>
    //                                                 </span>
    //                                             </div>
    //                                         </td>
    //                                     </tr>
    //                                 )
    //                             })
    //                         }

    //                     </tbody>
    //                 </table>
    //             </div>

    //             {selectedRow !== null && <ItemDetailTab
    //                 fileUploadLoader={fileUploadLoader}
    //                 handleFileAdd={handleFileAdd}
    //                 handleChange={handleChange}
    //                 selectedRow={selectedRow} />}
    //         </div>
    //     )
    // }
    return (
        <div className="w-full min-h-60 shadow rounded-md mt-3 p-2 bg-white">
            <h4 className="my-1 font-[600]">Item Details</h4>
            <div className="w-full pb-60 min-h-56 overflow-scroll">  {/* Keep overflow-scroll here */}
                <table className='text-sm w-full relative'>
                    <thead>
                        <tr className='text-left capitalize bg-gray-100'>
                            {cols.map((col) => (
                                <th
                                    key={col}
                                    title={col}
                                    scope='col'
                                    className='font-[500] p-2 border text-start'
                                >
                                    {col}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {items?.map(item => (
                            <React.Fragment key={item.uId}>
                                <tr
                                    className={`cursor-pointer text-left ${selectedRow?.uId === item.uId ? 'bg-gray-100 shadow-md' : 'bg-white'}`}
                                    onClick={() => setSelectedRow(item === selectedRow ? item : item)}
                                >
                                    {/* ... your table cells (all the <td> elements) remain unchanged ... */}
                                    <td role="button" onClick={() => setSelectedRow(item)} className='border p-1'>
                                        <input
                                            id={item?.uId}
                                            name="Style"
                                            onChange={handleStyleChange}
                                            value={item?.Style}
                                            className=" uppercase placeholder:font-[300] placeholder:capitalize outline-none w-full bg-transparent " placeholder="Search" />
                                    </td>
                                    <td role="button" onClick={() => setSelectedRow(item)} className='border p-1'>
                                        <input
                                            id={item?.uId}
                                            name="ConfigID"
                                            onChange={handleChange}
                                            className=" uppercase placeholder:font-[300] placeholder:capitalize outline-none w-full bg-transparent " placeholder="Search" />
                                    </td>
                                    <td role="button" onClick={() => setSelectedRow(item)} className='border p-1'>
                                        <input
                                            id={item?.uId}
                                            name="BatchID"
                                            onChange={handleChange}
                                            className=" uppercase placeholder:font-[300] placeholder:capitalize outline-none w-full bg-transparent " placeholder="Search" />
                                    </td>
                                    <td role="button" onClick={() => setSelectedRow(item)} className='border p-1'>
                                        <VersionDropdown data={item?.listOfVersions}
                                            rowUID={item?.uId}
                                            row={item}
                                            setItems={setItems}
                                            items={items}
                                            handleVersionSelect={handleVersionSelect}
                                            version={item?.Version} />
                                    </td>
                                    <td role="button" onClick={() => setSelectedRow(item)} className='border p-1'>
                                        <input
                                            id={item?.uId}
                                            name="CenterSize"
                                            onChange={handleChange}
                                            value={item?.CenterSize}
                                            disabled={item?.Center === "No" || item?.Style?.startsWith("ME") || item?.Style?.startsWith("RM")}
                                            className=" uppercase placeholder:font-[300] placeholder:capitalize outline-none w-full bg-transparent " placeholder="Size" />
                                    </td>
                                    <td role="button" onClick={() => setSelectedRow(item)} className='border p-1'>
                                        <select
                                            value={item?.Center}
                                            onChange={handleChange}
                                            name="Center"
                                            id={item?.uId}
                                            disabled={item?.Style?.startsWith("ME") || item?.Style?.startsWith("RM")}
                                            className="outline-none w-full bg-transparent">
                                            <option value="" disabled>Select</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No-Semi">No-Semi</option>
                                        </select>
                                    </td>
                                    <td role="button" onClick={() => setSelectedRow(item)} className='border p-1'>
                                        <select value={item?.CenterQuality || ''}
                                            onChange={handleChange}
                                            id={item?.uId}
                                            disabled={item?.Center === "No-Semi" || item?.Style?.startsWith("ME") || item?.Style?.startsWith("RM")}
                                            // value={item?.Center == "No" then ''}
                                            name="CenterQuality"
                                            className="outline-none w-full bg-transparent">
                                            <option value="" disabled>Select</option>
                                            {
                                                quality_types?.map((it => {
                                                    return <option key={it} value={it}>{it}</option>
                                                }))
                                            }
                                        </select>
                                    </td>
                                    {/* <td role="button" onClick={() => setSelectedRow(item)} className='border p-1'>
                                        <select value={item?.CustomerCenter || ''}
                                            onChange={handleChange}
                                            name="CustomerCenter"
                                            id={item?.uId}
                                            className="outline-none w-full bg-transparent">
                                            <option value="" disabled>Select</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </td> */}
                                    <td role="button" onClick={() => setSelectedRow(item)} className='border p-1'>
                                        <select value={item?.Metal || ''}
                                            onChange={handleChange}
                                            name="Metal"
                                            id={item?.uId}
                                            className="outline-none w-full bg-transparent">
                                            <option value="" disabled>Select</option>
                                            {
                                                metal_types?.map((it => {
                                                    return <option key={it} value={it}>{it}</option>
                                                }))
                                            }
                                        </select>
                                    </td>
                                    <td role="button" onClick={() => setSelectedRow(item)} className='border p-1'>
                                        <select value={item?.Quality || ''}
                                            onChange={handleChange}
                                            name="Quality"
                                            // disabled={item?.CustomerCenter == "Yes"}
                                            id={item?.uId}
                                            className="outline-none w-full bg-transparent">
                                            <option value="" disabled>Select</option>
                                            {
                                                quality_types?.map((it => {
                                                    return <option key={it} value={it}>{it}</option>
                                                }))
                                            }
                                        </select>
                                    </td>
                                    <td role="button" onClick={() => setSelectedRow(item)} className='border p-1'>
                                        <input
                                            onChange={handleChange}
                                            name="RingSize"
                                            id={item?.uId}
                                            value={item?.RingSize}
                                            className="outline-none w-full bg-transparent " placeholder="" />
                                    </td>
                                    <td role="button" onClick={() => setSelectedRow(item)} className='border p-1 w-20'>
                                        <input
                                            id={item?.uId}
                                            onChange={handleChange}
                                            name="Quantity"
                                            value={item?.Quantity || 1}
                                            className="outline-none bg-transparent " placeholder="" />
                                    </td>
                                    <td className='border p-1'>
                                        <div className="flex justify-start space-x-2">
                                            <span
                                                onClick={() => removeRow(item?.uId, getCartUID(item))}
                                                title='Delete'
                                                role="button"
                                                className='bg-gray-500 p-1 disabled:bg-gray-500/20 rounded-full text-white'
                                            >
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    viewBox='0 0 24 24'
                                                    fill='currentColor'
                                                    className='w-4 h-4'
                                                >
                                                    <path
                                                        fillRule='evenodd'
                                                        d='M3.75 12a.75.75 0 01.75-.75h15a.75.75 0 010 1.5h-15a.75.75 0 01-.75-.75z'
                                                        clipRule='evenodd'
                                                    />
                                                </svg>
                                            </span>
                                            <span
                                                title='Add'
                                                role="button"
                                                onClick={AddRow}
                                                className='bg-gray-500 p-1 disabled:bg-gray-500/20 rounded-full text-white'
                                            >
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    viewBox='0 0 24 24'
                                                    fill='currentColor'
                                                    className='w-4 h-4'
                                                >
                                                    <path
                                                        fillRule='evenodd'
                                                        d='M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z'
                                                        clipRule='evenodd'
                                                    />
                                                </svg>
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                                {selectedRow?.uId === item.uId && selectedRow !== null && (
                                    <tr className="border-b">
                                        <td colSpan={cols.length}>
                                            <ItemDetailTab
                                                fileUploadLoader={fileUploadLoader}
                                                handleFileAdd={handleFileAdd}
                                                handleChange={handleChange}
                                                selectedRow={selectedRow}
                                                imageURl={imageLink}
                                            />
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};


export default ItemTable;