// import { useEffect } from 'react'
import { ToastContainer } from 'react-toastify'
import Ring_Configurator from 'src/Layouts/Ring_Configurator'

const Ring_ConfiguratorPage = () => {

    return (
        <div className='w-full'>
            <Ring_Configurator />
            <ToastContainer
                position="bottom-center"
                newestOnTop limit={1} />
        </div>
    )
}

export default Ring_ConfiguratorPage