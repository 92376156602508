// import { AlertError, AlertSuccess } from "src/Services"
import moment from "moment"
import { postMethodApiCall } from "src/Services/API"
import { ACTION_GET_ORDERS, ACTION_GET_VERSION, ACTION_GET_STYLE_VERSIONS, ACTION_GET_ATTACHMENT_DETAILS, ACTION_GET_IMAGE_LINK_FROM_STYLE } from "src/Services/Constant/types"

const baseUrl = process.env.REACT_APP_BASE_URL

const getHeadersWithoutAuth = () => {
    return {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('token')}`
    }
}

export const GET_ORDERS = data => {
    return async dispatch => {
        try {
            let payload = {
                ...data,
                start_date: moment(new Date(data.start_date)).format('YYYY-MM-DD'),
                end_date: moment(new Date(data.end_date)).format('YYYY-MM-DD')
            }
            const url = `${baseUrl}/odata/get/sales_orders/`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                dispatch({ type: ACTION_GET_ORDERS, data: { list: res?.data?.sales_line_rows, pagination: res?.data?.pagination } || { list: 0, pagination: {} } })
            } else {
                return { success: false, message: res?.data?.message }
            }
        } catch (error) {
            console.log((error?.response?.data).replace(/\\/g, ""))
        }
    }
}

export const GET_VERSIONS = data => {
    return async dispatch => {
        try {
            const url = `${baseUrl}/odata/get/version`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                dispatch({ type: ACTION_GET_VERSION, data: res?.data?.rows })
                return res?.data?.rows
            } else {
                return { success: false, message: res?.data?.message }
            }
        } catch (error) {
            console.log((error?.response?.data).replace(/\\/g, ""))
        }
    }
}

export const GET_IMAGE = data => {
    return async dispatch => {
        try {
            const url = `${baseUrl}/odata/get/styleImage`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                dispatch({ type: ACTION_GET_IMAGE_LINK_FROM_STYLE, data: res?.data?.url})
                console.log("Printing IMAGE URL: ", res?.data?.url)
                return res?.data?.url
            } else {
                return { success: false, message: res?.data?.message }
            }
        } catch (error) {
            console.log((error?.response?.data).replace(/\\/g, ""))
        }
    }
}

export const GET_STYLE_VERSIONS = data => {
    return async dispatch => {
        try {
            const url = `${baseUrl}/odata/get/styles`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.data?.rows?.length > 0) {
                dispatch({ type: ACTION_GET_STYLE_VERSIONS, data: res?.data?.rows[0] })
                return res?.data?.rows[0]
            } else {
                return { success: false, message: res?.data?.message }
            }
        } catch (error) {
            console.log((error?.response?.data).replace(/\\/g, ""))
        }
    }
}


export const CREATE_NEW_ORDER = data => {
    return async dispatch => {
        try {
            const url = `${baseUrl}/odata/upload`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                return true
            } else {
                return false
            }
        } catch (error) {
            console.log((error?.response?.data).replace(/\\/g, ""))
        }
    }
}

export const GET_ATTCHMENT_DETAILS = data => {
    return async dispatch => {
        try {
            const url = `${baseUrl}/odata/attachment/details`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.data?.rows?.length > 0) {
                dispatch({ type: ACTION_GET_ATTACHMENT_DETAILS, data: res?.data?.rows })
            } else {
                return { success: false, message: res?.data?.message }
            }
        } catch (error) {
            console.log((error?.response?.data).replace(/\\/g, ""))
        }
    }
}

export const GET_IMAGE_LINK_BY_FILE = (file, lN, id) => {
    return async dispatch => {
        try {
            const formData = new FormData();
            let headers = {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
            formData.append('image',file)
            formData.append('line_number',lN)
            formData.append('OrderId',id)
            const url = `${baseUrl}/odata/get/image/upload`
            const res = await postMethodApiCall(url, headers, formData)
            if (res?.status === 200 || res?.status === 201) {
                return { success: true, link: res?.data?.image_path }
            } else {
                return { success: false, link: null }
            }
        } catch (error) {
            console.log((error?.response?.data).replace(/\\/g, ""))
        }
    }
}


